.landing-mos-info-container {
    margin: 20px 0px;
    padding: var(--mobile-padding);
}

.landing-mos-info-row-mobile {
    display: flex;
    justify-content: space-between;
    margin: 16px 0px;
}

.landing-mos-info-row-mobile-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 50%;
}

.landing-mos-info-mobile-title {
    color: gray;
    font-size: 2.4vw;
    margin: 0px 6px;
}

.landing-mos-info-mobile-value {
    font-size: 3.6vw;
}

.landing-mos-info-mobile-title a{
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-gray);
    font-size: 2vw;
    padding-left: 4px;
}

.vertical-rule {
    background: rgba(243, 182,78, 0.5);
    width: 1px;
    margin: 8px 0px;
}

.horizontal-rule {
    width: 100%;
    background: rgba(243, 182,78, 0.5);
    height: 1px
}


/* WEB */
.landing-mos-info-web-container {
    padding: var(--web-padding);
}

.mos-info-web-row {
    display: flex;
    flex:1;
}

.mos-info-top-row-element, .mos-info-bottom-row-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:calc(100%/4);
}

.mos-info-bottom-row-element {
    width: calc(100%/3);
}

.mos-info-web-title{
    color: var(--primary-gray);
    font-size: 1.3vw;
    text-align: center;
    padding-bottom: 16px;
}

.mos-info-web-title a {
    cursor: pointer;
    text-decoration: none;
    font-size: 1vw;
    color: var(--primary-gray);
    padding-left: 8px;
    margin-bottom: -2px;
}

.mos-info-question-mark {
    color: var(--primary-gray);
    font-weight: 400;
}

.mos-info-web-value{
    font-size: 1.8vw;
    font-weight: 600;
}

.vertical-rule-web {
    background: rgba(243, 182,78, 0.5);
    width: 1px;
    margin: 8px 0px;
}

.horizontal-rule-web {
    width: 100%;
    background: rgba(243, 182,78, 0.5);
    height: 1px;
    margin: 16px 0px;
}