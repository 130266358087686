.tdvv__container {
    color: white;
    padding: 8vw;
}

.tdvv__title {
    color: #F3B64E;
    font-size: 4vw;
}

.tdvv__desc {
    font-size: 3vw;
    color: var(--white70);
    line-height: 1.2;
}

.tdvv__right-contaner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 32px;
}

.tdvv__circle-background {
    background-image: url('../../assets/images/common/circle_golden.svg');
    height: 56vw;
    width: 56vw;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tdvv__blue-circle-background {
    background-image: url('../../assets/images/common/circle_blue.svg');
}

.tdvv__circle-title {
    font-size: 2.5vw;
    fill: var(--white30);
}

.tdvv__circle-value {
    font-size: 4vw;
    font-weight: 500;
    line-height: 1.1;
    fill: white;
}

.tdvv__item-container {
    padding: 16px 0px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F3B64E;
}

.tdvv__item-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ttdv__item-dot {
    height: 10px;
    width: 10px;
    background-color: #176EBF;
    border-radius: 50px;
    margin-right: 3vw;
}

.ttdv__item-title {
    font-size: 3.5vw;
    color: var(--white50);
}

.tdvv__item-amount {
    font-size: 4vw;
    font-weight: bold;
    color: white;
}

.tdvv__item-price {
    font-size: 3vw;
    font-weight: 700;
    color: var(--white90);
}


@media screen and (min-width: 768px) {
    .tdvv__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4vw 6vw;
    }

    .tdvv__left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 20vw;
    }

    .tdvv__title {
        font-size: 2vw;
        margin-bottom: 0.8vw;
    }

    .tdvv__desc {
        font-size: 1vw;
        color: white;
    }

    .tdvv__right-contaner {
        margin-bottom: 0;
        width: 26vw;
    }

    .tdvv__circle-background {
        height: 20vw;
        width: 20vw;
    }

    .tdvv__circle-title {
        font-size: 0.8vw;
        margin-top: -1.8vw;
    }

    .tdvv__circle-value {
        font-size: 1vw;
        margin-top: 4px;
        font-weight: 500;
    }

    .ttdv__item {
        display: flex;
        flex-direction: row;
    }

    .tdvv__item-container {
        border-bottom-width: 0px;
        margin-right: 5vw;
    }
    
    .tdvv__item-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.4vw;
    }
    
    .ttdv__item-dot {
        height: 10px;
        width: 10px;
        background-color: #176EBF;
        border-radius: 50px;
        margin-right: 0.7vw;
    }
    
    .ttdv__item-title {
        font-size: 1.2vw;
        /* margin-bottom: 0.4vw; */
        color: var(--white50);
    }
    
    .tdvv__item-amount {
        font-size: 1.4vw;
        font-weight: 500;
        color: white;
    }
    
    .tdvv__item-price {
        font-size: 1vw;
        font-weight: 400;
        color: var(--white90);
    }
}