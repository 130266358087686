.landing-line-chart-container {
    margin: 20px 0px;
    padding: var(--mobile-padding);
}

.landing-line-chart-canvas {
    height: 60vh;
    margin: 12px 0px;
}

.landing__line-chart-custom-tooltip {
    padding: 10px 16px;
    background: var(--primary-bg);
    color: var(--white80);
    border-radius: 10px;
}

@media screen and (min-width: 768px) {
    .landing-line-chart-container {
        margin: 40px 0px;
    }
}