.landing-table-container {
    padding: var(--mobile-padding);
    margin-top: 40px;
}

.landing-table-web{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    color: white;
}

.landing-table-web-header {
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.landing-table-web-data {
    border-top: 1px solid rgba(137,137,137,0.6);
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 10px 8px;
}

.landing-table-mobile {
    display: grid;
    grid-template-columns: 40px repeat(3, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    color: white;
    font-size: 12px;
    margin: 20px 10px;
    border: thin solid var(--primary-gray);
    border-radius: 6px;
}

.landing-table-title{
    color: var(--primary-yellow);
    font-size: 16px;
    margin: 16px;
    margin-top: 16px;
}

.landing-table-cells-1{
    /* border-right: thin solid var(--primary-gray); */
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
}

.landing-table-mobile-cells-upper-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1;
    border-bottom: thin solid var(--primary-gray);
    border-left: thin solid var(--primary-gray);
    padding: 5px;
    min-height: 74px;
}

.landing-table-mobile-cells-lower-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1;
    padding: 5px;
    min-height: 74px;
    border-left: thin solid var(--primary-gray);
}

.ltm-vertical-divider {
    align-self: stretch;
    background-color: var(--primary-gray);
    width: 1px;
}
 
.landing-table-question-mark {
    color: var(--primary-yellow);
    font-weight: 400;
    cursor: pointer;
}


@media screen and (min-width: 768px) {
    .landing-table-container {
        padding: var(--web-padding);
    }
}
