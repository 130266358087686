.copy-component-container {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--primary-yellow);
    padding: 5px 20px;
    text-align: center;
    /* margin: 10px 0px 50px -50px; */
    transition: all 0.5s;
}

.copy-component-title {
    color: var(--primary-gray-dark);
    font-size: 14px;
}

.copy-component-container:hover {
    cursor: pointer;
    background-color: #F4B956;
    box-shadow: 2px 2px 15px rgba(243,182,78,0.3);
}

@media screen and (min-width: 768px) {
    .copy-component-title {
        font-size: 24px;
    }

}