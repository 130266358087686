.cl__container {
    padding: 8vw;
    margin-top: 6vw;
    display: flex;
    flex-direction: column;
}

.cl__swipe-icon {
    height: 5.5vw;
    width: 5.5vw;
    margin: 3vw;
}

.cl__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cl__tooltip-question-mark {
    color: var(--primary-gray);
    padding: 4px 8px 4px 10px;
    font-size: 12px;
}

.cl__title-qm {
    margin-top: -3px;
    padding-left: 8px;
}

.cl__tooltip-question-mark a {
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-gray);
}

@media screen and (min-width: 768px) {
    .cl__container {
        padding: 4vw 6vw;
    }
    
    .cl__swipe-icon {
        height: 2.5vw;
        width: 2.5vw;
        margin: -2vw 3vw 0px 3vw;
    }

    .cl__rotate-icon {
        transform: rotate(90deg);
    }

    .cl__top-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 1.5vw;
    }

    .cl__right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 11vw;
    }

    .cl__bottom-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
    }

    .cl__tooltip-question-mark {
        padding: unset;
        padding-right: 8px;
        font-size: unset;
    }

    .cl__title-qm {
        padding-top: 18px;
        margin-top: unset;
    }
}