#tron-listener-modal .modal-content {
    background-color: var(--primary-bg);
}

#tron-listener-modal .modal-header {
    color: var(--primary-yellow);
    padding: 0.7rem;
    border-bottom: 1px solid  var(--white10);
}

#tron-listener-modal .modal-title {
    font-size: 20px !important;
}

#tron-listener-modal .modal-body {
    color: white;
    padding: 1.5rem 1rem;
}

#tron-listener-modal .modal-footer {
    border-top: 1px solid var(--white10);
    padding: 0 1rem;
}

.tron-listener-modal-button {
    border-radius: 5px;
    background-color: var(--primary-yellow);
    text-align: center;
    transition: all 0.5s;
    padding: 0;
    margin: 0;
}

.tron-listener-modal-connect {
    border: thin solid var(--primary-yellow);
    background-color: #FFFFFF00;
    min-width: 120px;
    padding: 1vw;
    margin: 2vw 0px;
    color: var(--primary-yellow);
}

.tron-listener-modal-connect:hover {
    color: unset;
    cursor: pointer;
    background-color: #F4B956;
    box-shadow: 2px 2px 15px rgba(243,182,78,0.3);
}



.tron-listener-modal-button-title {
    /* font-size: 4vw; */
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    .tron-listener-modal-button {
        padding: 6px 32px;
        margin: 12px;
    }

    .tron-listener-modal-connect {
        padding: 0.2vw 0.6vw;
        margin: 0 0 0 0.6vw;
    }
    
    .tron-listener-modal-button-title {
        /* font-size: 1.1vw; */
    }
}