.ans__container {
    padding: 8vw;
    margin-top: 6vw;
}

.ans__p-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3vw 3vw;
    margin-top: 4vw;
}

.ans__p-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ans__p-title {
    color: #F3B64E;
    font-size: 4vw;
}

.ans__p-icon {
    width: 3vw;
    height: 3vw;
    margin-left: 1vw;
    border-radius: 50px;
}

.ans__p-date {
    font-size: 3vw;
    color: var(--white60);
    margin-left: auto;
}

.ans__horizontal-divier {
    height: 1px;
    background-color: #F3B64E;
    margin: 5vw 0vw;
}

.ans__vertical-divier {
    width: 1px;
    background-color: #F3B64E;
}

.ans__item {
    display: flex;
    flex-direction: row;

    padding-bottom: 5vw;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F3B64E;
}

.ans__item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ans__item-title {
    font-size: 4vw;
    font-weight: bold;
    color: white;
    text-align: center;
    word-break: break-word;
    margin: 0 20px;
}

.ans__item-value {
    font-size: 3vw;
    color: var(--white40);
    text-align: center;
    line-height: 1.1;
}

.ans__item-subvalue {
    font-size: 2vw;
    color: var(--white90);
    text-align: center;
    margin-top: 0.5vw;
}

.ans__item-container a {
    text-decoration: none;
    color: var(--white90);
}

.ans__item-tooltip {
    text-align: center;
}

.ans__item-question-mark {
    color: var(--primary-gray);
    font-size: 1.8vw;
}

.ans__slider {
    margin: 8vw 6vw 0px 6vw;
    padding-bottom: 8vw;
}

.MuiSlider-markLabel {
    color: white !important;
    font-size: 3vw !important;
}

.MuiSlider-markLabelActive {
    color: #F3B64E !important;
}

.tooltip-img {
    height: 25vh;
    padding: 8px 0px;
}

@media screen and (min-width: 768px) {
    .ans__container {
        padding: 4vw 6vw;
    }

    .ans__header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .ans__title-container {
        margin-right: auto;
    }

    .ans__p-container {
        padding: 0.5vw 1vw;
        flex-direction: column;
        align-items: flex-start;
        width: 19vw;
        margin-top: 0px;
        margin-left: 2vw;
    }
    
    .ans__p-title {
        color: #F3B64E;
        font-size: 1.5vw;
    }
    
    .ans__p-icon {
        width: 1vw;
        height: 1vw;
        margin-left: 0.5vw;
        border-radius: 50px;
    }
    
    .ans__p-date {
        font-size: 1vw;
        color: var(--white80);
        margin-left: 0;
    }

    .ans__body-container {
        display: flex;
        flex-direction: row;
        margin-top: 2vw;
        padding: 2vw 0px;

        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #F3B64E;

        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #F3B64E;
    }

    .ans__body-container-column {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ans__body-right-container {
        /* display: flex;
        flex-direction: row; */
        width: 65%;

        border-width: 1px;
        border-style: solid;
        border-color: #F3B64E;

        border-top-width: 0px;
        border-bottom-width: 0px;
        
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 1px;
        grid-row-gap: 0px;

        background-color: #F3B64E;
        margin-left: 3vw;
    }

    .ans__item-container {
        width: unset;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin: 0px 2vw; */
        padding: 1vw;

        background:
            linear-gradient(
            to left, 
            #ffffff00 15%,
            #F3B64E 15%,
            #F3B64E 85%,
            #ffffff00 85%
            )
            left 
            bottom
                no-repeat
            ,
            var(--primary-gray-dark)
            top
            left
        ; 
        background-size:100% 1px , 100% auto;
    }

    .ans__item-container:nth-last-child(-n+3) {
        background:
            var(--primary-gray-dark)
            top
            left
        ; 
        background-size:100%;

    }
        
    .ans__item-title {
        font-size: 1.3vw;
        font-weight: 500;
        margin: 0px;
    }
    
    .ans__item-value {
        font-size: 1.2vw;
    }
    
    .ans__item-subvalue {
        font-size: 1vw;
        color: var(--white90);
        text-align: center;
        margin-top: 0.1vw;
    }

    .ans__item-question-mark {
        font-size: 0.8vw;
    }

    .ans__slider {
        margin: 4vw 3vw;
        padding-bottom: unset;
    }

    .MuiSlider-markLabel {
        font-size: 1.2vw !important;
    }
}
