
.refer-link-container {
    padding: var(--mobile-padding);
}

.refer-link-title {
    font-size: 18px;
    color: var(--primary-yellow);
}

.refer-copy-text {
    color: var(--white);
    font-size: 14px;
    border: 1px solid#898989;
    padding: 10px 30px;
    border-radius: 10px;
    margin: 10px 0 50px 0;
    display: flex;
    flex: 1;
}

.refer-link-subtitle-div {
    /* width: 90%; */
}

.refer-link-subtitle {
    font-size: 16px;
    color: #898989;
}

.refer-earn-subtitle {
    font-size: 14px;
    font-weight: 300;
}

.refer-earn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--mobile-padding);
    flex: 1;
    margin-top: 40px;
}

.refer-total-value {
    font-weight: bold;
    color: var(--primary-yellow);
    margin: 5px 0;
    font-size: 32px;
}

.refer-earning-value {
    color: var(--white);
    font-size: 18px;
}

.refer-logo {
    display: flex;
    margin: auto;
}

.refer-table-title {
    font-size: 14px;
    font-weight: 300;
    padding-left: 5px;
}

.refer-side-picture {
    background-image: url('../../assets/images/refer/refer-pic.png');
    background-size: cover;
    margin-left: 24px;
    padding: 40px;
    flex: 1;
}

.refer-bottom-container {
    padding: var(--table-padding);
    margin-top: 40px;
}

.refer-top-left-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}


.refer-referral-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    color: var(--primary-gray);
    font-size: 14px;
    border: 1px solid var(--primary-gray);
    border-radius: 10px;
    margin-top: 24px;
    /* max-width:40%; */
}

.refer-referral-link-content{
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width:100%;
}

/* TABLE MOBILE */
.refer-table-mobile {
    display: grid;
    /* grid-template-columns: repeat(4,1fr); */
    /* grid-template-rows: repeat(4, 1fr); */
    /* grid-column-gap: 0px; */
    /* grid-row-gap: 0px;  */

    display: grid;
    grid-template-columns: 0.4fr 2fr;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-rows: repeat(3, 1fr); 

    color: white;
    padding: 16px 10px 0px;
    font-size: 12px;
    margin-bottom: 16px;
}

.refer-table-title{
    color: var(--primary-yellow);
    font-size: 16px;
    /* margin: 10px; */
}

.refer-table-cells-1{
    border-right: thin solid var(--primary-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    font-size: 14px;
}

.refer-table-mobile-cells {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex:1;
    border-bottom: thin solid var(--primary-gray);
    padding: 5px;
    height: 60px;
}

.refer-table-address {
    display: inline-block;
    width: auto;
    max-width: 200px;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.refer-table-mobile-cells:last-child {
    border-bottom: none;
}
/* end */


/* TABLE WEB */
.refer-table-web{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    color: white;
}

.refer-table-web-header {
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 14px;
}

.refer-table-web-data {
    border-top: 1px solid rgba(137,137,137,0.6);
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 10px 8px;
}
/* end */

@media screen and (min-width: 768px) {
    .refer-link-container {
        padding: var(--web-padding);
    }

    .refer-earn-container {
        flex-direction: column;
        padding: var(--web-padding);
    }

    .refer-copy-text {
        font-size: 24px;
        justify-content: center;
        color: var(--white);
        border: 1px solid#898989;
        padding: 0 100px;
        border-radius: 10px;
        display: flex;
        flex: 1;
    }

    .refer-link-title {
        font-size: 24px;
    }

    .refer-earn-subtitle {
        font-size: 18px;
    }

    .refer-bottom-container {
        padding: var(--web-padding);
    }

    .refer-referral-link-container {
        /* max-width: 80%; */
    }

}

@media screen and (min-width: 1000px) {
    .refer-top-left-wrapper {
        width: 50%;
    }

}