.button-component-container {
    border-radius: 5px;
    background-color: var(--primary-yellow);
    padding: 10px;
    text-align: center;
    margin: 20px 0px;
    transition: all 0.5s;
}

.button-component-title {
    color: var(--primary-gray-dark);
    font-size: 18px;
}

.button-component-container:hover {
    cursor: pointer;
    background-color: #F4B956;
    box-shadow: 2px 2px 15px rgba(243,182,78,0.3);
}

@media screen and (min-width: 768px) {
    .button-component-title {
        font-size: 24px;
    }

}