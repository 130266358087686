* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  outline: none;
  box-sizing:border-box;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(40,40,40,1);
}

::-webkit-scrollbar-thumb {
  background:rgba(255,255,255,0.2);
  border-radius: 4px;
}

:root {
  --primary-yellow-light: #FFE98D;
  --primary-yellow: #F3B64E;
  --primary-gray: #898989;
  --primary-gray-dark: #282828;

  --primary-bg: #222222;

  --white: #ffffff;
  --white90: rgba(255,255,255,0.9);
  --white80: rgba(255,255,255,0.8);
  --white70: rgba(255,255,255,0.7);
  --white60: rgba(255,255,255,0.6);
  --white50: rgba(255,255,255,0.5);
  --white40: rgba(255,255,255,0.4);
  --white30: rgba(255,255,255,0.3);
  --white20: rgba(255,255,255,0.2);
  --white10: rgba(255,255,255,0.1);
  --blue: #176EBF;
  --light-blue: #00BDFF;
  --light-green: #E8EFCE;

  --web-padding: 40px 60px;
  --mobile-padding: 20px 30px;
  --table-padding: 20px 10px;
}

.outer-content-wrapper {
  border-radius: 18px;
  background-color: #282828;
  box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

.content-wrapper {
  border-radius: 6px;
  background-color: #282828;
  box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

.font-lora {
  font-family: "Lora", sans-serif;
}

.font-sf-pro {
  font-family: "SFPro", serif;
}

@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url('./fonts/Lora-Regular.ttf') format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "SFPro";
  src: local("SFPro"),
    url('./fonts/SF-Pro.ttf') format("truetype");
  font-weight: 400;
}
