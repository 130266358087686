.loader-container .modal-content {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0px;
}

.modal-backdrop {
    background-color: rgba(0,0,0,1);
}
  