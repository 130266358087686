.web-header__container {
    width: 95%;
    margin: auto;
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: rgba(255, 255, 255, 0.15);
    padding: 0 !important;
}

.web-header__language #basic-nav-dropdown {
    text-transform: uppercase;
    color: #F3B64E;
    margin-left: 12px;
}

.web-header__language__container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.web-header__nav-body {
    width: 66%;  
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.web-header-link {
    height: 5vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Lora";
    color: white;
}

.navbar-nav .active {
    color: #F3B64E !important;
    border-bottom: 1px solid #F3B64E;
}

.web-header-link:hover {
    color: #F3B64E;
    text-decoration: unset;
}

.web-header__nav-item .active {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F3B64E !important;
    border-bottom-color: #F3B64E;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.dropdown-toggle::after {
    display: none;
}