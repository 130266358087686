.mobile-header__nav-body {
    display: flex;
    flex-direction: column;
}

.mobile-header-link {
    height: 5vw;
    font-family: "Lora";
    color: white;
    margin: 4vw 0px;
}

.mobile-header-link:hover {
    color: #F3B64E;
    text-decoration: unset;
}

.mobile-header-language {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-gray-dark);
    width: 65vw;
}

.mobile-header-language a{
    border-top: 1px solid var(--white20);
}

.mobile-header-language a:nth-child(1) {
    border-top: unset;
}