.asv__container {
    padding: 8vw;
    margin-top: 6vw;
}

.asv-table-web {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    color: white;

    padding: 2vw 2vw 3vw 2vw;
    margin-top: 3vw;
}

.asv-table-web-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 4vw;
    text-align: center;
    color: #F3B64E;

    padding-top: 1vw;
}

.asv-table-web-data {
    font-size: 4vw;
    text-align: center;
    padding-top: 1vw;
}

.asv-table-web-divide-top {
    border-top: 1px solid rgba(137,137,137,0.6);
    margin-top: 2vw;
    padding-top: 2vw;
}

.asv__container a, .asv-table-web a{
    text-decoration: none;
}

.asv-table-question-mark {
    color: var(--primary-yellow);
    font-weight: 400;
}

.asv-table-question-mark-mobile {
    color: var(--primary-gray);
    font-weight: 400;
}

.asv-table-end-stake-note {
    color: white;
    font-size: 12px;
    text-align: center;
}

.eesm__container {
    padding: 2vw;
    color: white;
}

.asv__early-end-modal-link {
    color: var(--primary-yellow);
    text-decoration: none;
    cursor: pointer;
    padding-left: 6px;
}

@media screen and (min-width: 768px) {
    .asv__container {
        padding: 4vw 6vw;
    }
    /* TABLE WEB */
    .asv-table-web {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px; 
        color: white;
        padding: 0px;
        margin-top: 0px;

        /* border-top: 1px solid rgba(137,137,137,0.6); */
        /* border-left: 1px solid rgba(137,137,137,0.6); */
        /* border-right: 1px solid rgba(137,137,137,0.6); */
    }

    .asv-table-web-header {
        border-left: 1px solid rgba(137,137,137,0.6);
        /* border-bottom: 1px solid rgba(137,137,137,0.6); */
        padding: 8px 0px;
        /* padding-top: 20px; */
        font-weight: bold;
        font-size: 0.9vw;
        text-align: center;
        color: white;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .asv-table-web-header:nth-child(1), .asv-table-web-new-header:nth-child(1){
        border-left: unset;
    }

    .asv-table-web-data {
        border-top: 1px solid rgba(137,137,137,0.6);
        border-left: 1px solid rgba(137,137,137,0.6);
        padding: 10px 8px;
        font-size: 1vw;
        text-align: right;
    }


    .asv-table-web-new {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        /* grid-template-columns: repeat(14, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        color: white;
    }

    .asv-table-web-new-history {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        /* grid-template-columns: repeat(13, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        color: white;

    }

    .asv-table-web-new-header {
        border-left: 1px solid rgba(137,137,137,0.6);
        /* font-weight: bold; */
        font-size: 0.9vw;
        text-align: center;
        padding: 10px 8px;

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .asv-table-web-new-header a {
        text-decoration: none;
        cursor: pointer;
        color: var(--primary-yellow);
    }

    .asv-table-web-new-header span {
        white-space: nowrap;
    }

    /* .asv-table-web-new-header:nth-child(4),
    .asv-table-web-new-header:nth-child(11),
    .asv-table-web-new-header:nth-child(14) {
        border-bottom: 1px solid rgba(137,137,137,0.6);
    } */

    .asv-table-web-data:nth-child(1){
        border-left: unset;
    }

    .asv-exit-btn {
        box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
        color: #F3B64E;
        padding: 2px;
        text-align: center;
    }

    .asv-exit-btn:hover {
        cursor: pointer;
        transform: scale(1.05,1.05);
    }
    /* end */
}