.landing-pie-overview-container {
    /* display: flex;
    flex-wrap: wrap; */
    /* margin-top: 40px; */
}

.landing-pie-container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex:1;
    padding:var(--mobile-padding);
}

.landing-pie-canvas {
    /* height: 25vh; */
    margin-top: 18px;
}

.landing-pie-legend-container{
    display: flex;
    width: 74%;
    justify-content: space-evenly;
    align-items: center;
    margin: 24px auto 0px;
}

.landing-pie-legend-element {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-pie-legend-element span {
    margin: 5px 5px;
    color: white;
    font-size: 16px;
}

.landing-pie-legend-circle{
    border-radius: 50%;
    width: 8px;
    height:8px;
}

.landing-asset-overview-container {
    flex:1.5;
    padding: var(--mobile-padding);
    margin-top: 40px;
}

.landing-title {
    display: inline-block;
    font-size: 3vw;
    color: var(--primary-yellow);
    margin-bottom: 12px;
}

.landing-title-units {
    font-size: 1.5vw;
}

/* .landing-asset-overview-grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
} */

.landing-asset-overview-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 14px;
}

.asset-overview-info {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    /* padding-top: 12px; */
}

.asset-overview-element-title, .landing-mos-price, .landing-mos-price, .mos-stats-title {
    font-size: 2.4vw;
    color: white;
    margin-right: 10px;
}

.landing-mos-price {
    margin:unset;
}

.asset-overview-element-value, .landing-mos-price-value, .mos-stats-value, .landing-pie-legend-value {
    font-size: 3.6vw;
    color: var(--primary-yellow);
    font-weight: bold;
}

.asset-overview-element-value-usd {
    font-size: 3vw;
    color: var(--primary-yellow);
    text-align: right;
}

.landing-pie-legend-value {
    font-weight:normal;
}

@media screen and (min-width: 768px) {
    .landing-pie-overview-container {
        margin-top: unset;
    }

    .landing-pie-overview-container {
        display: flex;
        flex-wrap: wrap;
    }

    .landing-pie-container {
        margin: unset;
    }
    
    .landing-pie-canvas {
        height: 36vh;
    }

    .landing-asset-overview-container {
        margin: unset;
        margin-left: 20px;
        padding: var(--web-padding);
    }

    .landing-asset-overview-element {
        margin: 14px 0px;
        padding: 18px 22px;
    }

    .landing-title {
        font-size: 2vw;
    }

    .asset-overview-element-title, .landing-mos-price, .mos-stats-title {
        font-size: 1.3vw;
    }

    .asset-overview-element-value, .landing-mos-price-value, .mos-stats-value, .landing-pie-legend-value {
        font-size: 1.7vw;
    }

    .asset-overview-element-value-usd{
        font-size: 1.1vw;
    }

}