* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222222;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  outline: none;
  box-sizing:border-box;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(40,40,40,1);
}

::-webkit-scrollbar-thumb {
  background:rgba(255,255,255,0.2);
  border-radius: 4px;
}

:root {
  --primary-yellow-light: #FFE98D;
  --primary-yellow: #F3B64E;
  --primary-gray: #898989;
  --primary-gray-dark: #282828;

  --primary-bg: #222222;

  --white: #ffffff;
  --white90: rgba(255,255,255,0.9);
  --white80: rgba(255,255,255,0.8);
  --white70: rgba(255,255,255,0.7);
  --white60: rgba(255,255,255,0.6);
  --white50: rgba(255,255,255,0.5);
  --white40: rgba(255,255,255,0.4);
  --white30: rgba(255,255,255,0.3);
  --white20: rgba(255,255,255,0.2);
  --white10: rgba(255,255,255,0.1);
  --blue: #176EBF;
  --light-blue: #00BDFF;
  --light-green: #E8EFCE;

  --web-padding: 40px 60px;
  --mobile-padding: 20px 30px;
  --table-padding: 20px 10px;
}

.outer-content-wrapper {
  border-radius: 18px;
  background-color: #282828;
  box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

.content-wrapper {
  border-radius: 6px;
  background-color: #282828;
  box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

.font-lora {
  font-family: "Lora", sans-serif;
}

.font-sf-pro {
  font-family: "SFPro", serif;
}

@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url(/static/media/Lora-Regular.17e9dc52.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "SFPro";
  src: local("SFPro"),
    url(/static/media/SF-Pro.2f9118d7.ttf) format("truetype");
  font-weight: 400;
}

.mobile-header__nav-body {
    display: flex;
    flex-direction: column;
}

.mobile-header-link {
    height: 5vw;
    font-family: "Lora";
    color: white;
    margin: 4vw 0px;
}

.mobile-header-link:hover {
    color: #F3B64E;
    text-decoration: unset;
}

.mobile-header-language {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-gray-dark);
    width: 65vw;
}

.mobile-header-language a{
    border-top: 1px solid var(--white20);
}

.mobile-header-language a:nth-child(1) {
    border-top: unset;
}
.button-component-container {
    border-radius: 5px;
    background-color: var(--primary-yellow);
    padding: 10px;
    text-align: center;
    margin: 20px 0px;
    transition: all 0.5s;
}

.button-component-title {
    color: var(--primary-gray-dark);
    font-size: 18px;
}

.button-component-container:hover {
    cursor: pointer;
    background-color: #F4B956;
    box-shadow: 2px 2px 15px rgba(243,182,78,0.3);
}

@media screen and (min-width: 768px) {
    .button-component-title {
        font-size: 24px;
    }

}
#tron-listener-modal .modal-content {
    background-color: var(--primary-bg);
}

#tron-listener-modal .modal-header {
    color: var(--primary-yellow);
    padding: 0.7rem;
    border-bottom: 1px solid  var(--white10);
}

#tron-listener-modal .modal-title {
    font-size: 20px !important;
}

#tron-listener-modal .modal-body {
    color: white;
    padding: 1.5rem 1rem;
}

#tron-listener-modal .modal-footer {
    border-top: 1px solid var(--white10);
    padding: 0 1rem;
}

.tron-listener-modal-button {
    border-radius: 5px;
    background-color: var(--primary-yellow);
    text-align: center;
    transition: all 0.5s;
    padding: 0;
    margin: 0;
}

.tron-listener-modal-connect {
    border: thin solid var(--primary-yellow);
    background-color: #FFFFFF00;
    min-width: 120px;
    padding: 1vw;
    margin: 2vw 0px;
    color: var(--primary-yellow);
}

.tron-listener-modal-connect:hover {
    color: unset;
    cursor: pointer;
    background-color: #F4B956;
    box-shadow: 2px 2px 15px rgba(243,182,78,0.3);
}



.tron-listener-modal-button-title {
    /* font-size: 4vw; */
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    .tron-listener-modal-button {
        padding: 6px 32px;
        margin: 12px;
    }

    .tron-listener-modal-connect {
        padding: 0.2vw 0.6vw;
        margin: 0 0 0 0.6vw;
    }
    
    .tron-listener-modal-button-title {
        /* font-size: 1.1vw; */
    }
}
.web-header__container {
    width: 95%;
    margin: auto;
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: rgba(255, 255, 255, 0.15);
    padding: 0 !important;
}

.web-header__language #basic-nav-dropdown {
    text-transform: uppercase;
    color: #F3B64E;
    margin-left: 12px;
}

.web-header__language__container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.web-header__nav-body {
    width: 66%;  
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.web-header-link {
    height: 5vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Lora";
    color: white;
}

.navbar-nav .active {
    color: #F3B64E !important;
    border-bottom: 1px solid #F3B64E;
}

.web-header-link:hover {
    color: #F3B64E;
    text-decoration: unset;
}

.web-header__nav-item .active {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F3B64E !important;
    border-bottom-color: #F3B64E;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.dropdown-toggle::after {
    display: none;
}
.auction-top-container {
    color: white;
    display: flex;
    flex-direction: column;
    padding: var(--mobile-padding);
}

.auction-top-logo-container{
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.auction-top-title {
    font-size: 18px;
    color: var(--primary-yellow);
}

.auction-top-subtitle {
    font-size: 14px;
    font-weight: 300;
}

.auction-rate-container{
    display: flex;
    flex-direction: column;
}

.auction-rate-title {
    font-size: 16px;
    color: var(--primary-yellow);
}

.auction-logo{
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.auction-bid-container {
    display: flex;
    flex-direction: column;
    padding: var(--mobile-padding);
    flex:1 1;
}

.auction-usdt-balance {
    color: var(--white);
    text-align: right;
    /* margin-bottom: -14px; */
    font-size: 12px;
}

.bid-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px; 
}

.bid-value-container {
    margin-top: 10px;
    padding: 20px;
}

.bid-value-mos-container {
    padding: 20px;
    margin-bottom: 10px;
}

.bid-coin-logo {
    width: 30px;
}

.bid-value-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
    flex:1 1;
    padding-left: 16px;
    max-width: 85%;
}

.auction-deposit-input {
    font-weight: bold;
    color: var(--primary-yellow);
    font-size: 18px;
    text-align: end;
    /* width:80%; */
    border: none;
    flex:1 1;
    max-width: 75%;
}

.auction-deposit-input:hover {
    border-radius: 5px;
    background-color: var(--white10);
}

.auction-rate-value {
    font-weight: bold;
    color: var(--primary-yellow);
    font-size: 18px;
    text-align: right;
}

.auction-input-max {
    padding-left: 8px;
    font-size: 14px;
    color: white;
    text-align: right;
}

.auction-input-max:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
}

.auction-rate-sub {
    color: white;
    font-size: 14px;
    text-align: end;
}

.bid-arrow-circle {
    z-index: 2;
    margin: auto;
    padding: 8px;
    border-radius: 50%;
    transition: all 0.6s;
}

.bid-arrow {
    color: var(--primary-yellow);
    width: 20px;
    height: 20px;
}

.bid-side-picture {
    background-image: url(/static/media/auction-pic.f1979a35.png);
    background-size: cover;
    margin-left: 24px;
    flex: 1 1;
}

.auction-bottom-container {
    padding: var(--mobile-padding);
    margin-top: 40px;
}

.bid-footnote {
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 18px;
}

.bid-referral-wrapper {
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    padding: 0px 0px;
}

.bid-referral-wrapper input{
    font-weight: bold;
    color: var(--primary-yellow);
    font-size: 16px;
    text-align: end;
    /* width:80%; */
    border: none;
    flex:1 1;
    max-width: 75%;
}

.bid-referral-warning {
    color: white;
    text-align: center;
    font-size: 12px;
    padding-bottom: 8px;
}


/* TABLE MOBILE */
.auction-table-mobile {
    display: grid;
    grid-template-columns: 40px repeat(3, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    color: white;
    font-size: 12px;
    margin: 20px 10px;
    border: thin solid var(--primary-gray);
    border-radius: 6px;
}

.auction-table-title{
    color: var(--primary-yellow);
    font-size: 16px;
    margin: 16px;
    margin-top: 16px;
}

.auction-table-cells-1{
    /* border-right: thin solid var(--primary-gray); */
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
}

.auction-table-mobile-cells-upper-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1 1;
    border-bottom: thin solid var(--primary-gray);
    border-left: thin solid var(--primary-gray);
    padding: 5px;
    /* height: 96px; */
}

.auction-table-mobile-cells-lower-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1 1;
    padding: 5px;
    height: 96px;
    border-left: thin solid var(--primary-gray);
}

.auction-table-mobile-exit-btn {
    margin: 0px auto 34px;
    width: 50%;
    color: var(--primary-yellow);
    text-align: center;
}

.auction-table-mobile-exit-btn:hover {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
}

.auction-table-mobile-value {
    word-break: break-all;
}
/* end */


/* TABLE WEB */
.auction-table-web{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    color: white;
}

.auction-table-web-header {
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
}

.auction-table-web-data {
    border-top: 1px solid rgba(137,137,137,0.6);
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 10px 8px;
}
/* end */

.auction-exit-btn {
    box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

.auction-exit-btn:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05,1.05);
            transform: scale(1.05,1.05);
}

@media screen and (min-width: 768px) {
    .auction-top-container {
        flex-direction: row;
        /* align-items: center; */
        padding: var(--web-padding);
    }

    .auction-top-logo-container{
        margin: unset;
        width: 50%;
        max-width: 40vw;
        margin-left: auto;
    }

    .auction-top-title {
        font-size: 2vw;
    }

    .auction-top-subtitle {
        font-size: 18px;
    }


    .auction-logo{
        width:60px;
        height: 60px;
    }

    .auction-bid-container {
        padding: var(--web-padding);
    }


    .bid-value-container, .bid-value-mos-container {
        padding: 32px 60px;
    }

    .auction-rate-value, .auction-deposit-input {
        font-size: 28px;
    }

    .auction-input-max {
        font-size: 20px;
    }

    .auction-rate-sub {
        font-size:20px;
    }

    .bid-coin-logo {
        width: 48px;
    }

    .bid-arrow {
        width: 32px;
        height: 32px;
    }

    .bid-footnote, .auction-usdt-balance {
        font-size: 16px;
    }

    .auction-bottom-container {
        padding: var(--web-padding);
    }

    .bid-value-wrapper {
        max-width: unset;
    }

    .auction-deposit-input{
        max-width: unset;
    }

    .bid-referral-wrapper {
        font-size: 18px;
    }

    .bid-referral-warning {
        text-align: right;
        width: 75%;
        align-self: end;
        font-size: 14px;
    }
}
.app-container {
    transition: 0.5s;
    min-height: 100vh;
}

.content-container {
    padding: 3.6vw 4vw 5.6vw;
}


@media only screen and (min-width: 768px) {
    .content-container {
        padding: 4vw 8vw 5vw;
    }
}
.loader-container .modal-content {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0px;
}

.modal-backdrop {
    background-color: rgba(0,0,0,1);
}
  

.refer-link-container {
    padding: var(--mobile-padding);
}

.refer-link-title {
    font-size: 18px;
    color: var(--primary-yellow);
}

.refer-copy-text {
    color: var(--white);
    font-size: 14px;
    border: 1px solid#898989;
    padding: 10px 30px;
    border-radius: 10px;
    margin: 10px 0 50px 0;
    display: flex;
    flex: 1 1;
}

.refer-link-subtitle-div {
    /* width: 90%; */
}

.refer-link-subtitle {
    font-size: 16px;
    color: #898989;
}

.refer-earn-subtitle {
    font-size: 14px;
    font-weight: 300;
}

.refer-earn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--mobile-padding);
    flex: 1 1;
    margin-top: 40px;
}

.refer-total-value {
    font-weight: bold;
    color: var(--primary-yellow);
    margin: 5px 0;
    font-size: 32px;
}

.refer-earning-value {
    color: var(--white);
    font-size: 18px;
}

.refer-logo {
    display: flex;
    margin: auto;
}

.refer-table-title {
    font-size: 14px;
    font-weight: 300;
    padding-left: 5px;
}

.refer-side-picture {
    background-image: url(/static/media/refer-pic.3cf62549.png);
    background-size: cover;
    margin-left: 24px;
    padding: 40px;
    flex: 1 1;
}

.refer-bottom-container {
    padding: var(--table-padding);
    margin-top: 40px;
}

.refer-top-left-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}


.refer-referral-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    color: var(--primary-gray);
    font-size: 14px;
    border: 1px solid var(--primary-gray);
    border-radius: 10px;
    margin-top: 24px;
    /* max-width:40%; */
}

.refer-referral-link-content{
  display:inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width:100%;
}

/* TABLE MOBILE */
.refer-table-mobile {
    display: grid;
    /* grid-template-columns: repeat(4,1fr); */
    /* grid-template-rows: repeat(4, 1fr); */
    /* grid-column-gap: 0px; */
    /* grid-row-gap: 0px;  */

    display: grid;
    grid-template-columns: 0.4fr 2fr;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-rows: repeat(3, 1fr); 

    color: white;
    padding: 16px 10px 0px;
    font-size: 12px;
    margin-bottom: 16px;
}

.refer-table-title{
    color: var(--primary-yellow);
    font-size: 16px;
    /* margin: 10px; */
}

.refer-table-cells-1{
    border-right: thin solid var(--primary-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    font-size: 14px;
}

.refer-table-mobile-cells {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex:1 1;
    border-bottom: thin solid var(--primary-gray);
    padding: 5px;
    height: 60px;
}

.refer-table-address {
    display: inline-block;
    width: auto;
    max-width: 200px;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.refer-table-mobile-cells:last-child {
    border-bottom: none;
}
/* end */


/* TABLE WEB */
.refer-table-web{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    color: white;
}

.refer-table-web-header {
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 14px;
}

.refer-table-web-data {
    border-top: 1px solid rgba(137,137,137,0.6);
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 10px 8px;
}
/* end */

@media screen and (min-width: 768px) {
    .refer-link-container {
        padding: var(--web-padding);
    }

    .refer-earn-container {
        flex-direction: column;
        padding: var(--web-padding);
    }

    .refer-copy-text {
        font-size: 24px;
        justify-content: center;
        color: var(--white);
        border: 1px solid#898989;
        padding: 0 100px;
        border-radius: 10px;
        display: flex;
        flex: 1 1;
    }

    .refer-link-title {
        font-size: 24px;
    }

    .refer-earn-subtitle {
        font-size: 18px;
    }

    .refer-bottom-container {
        padding: var(--web-padding);
    }

    .refer-referral-link-container {
        /* max-width: 80%; */
    }

}

@media screen and (min-width: 1000px) {
    .refer-top-left-wrapper {
        width: 50%;
    }

}
.copy-component-container {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--primary-yellow);
    padding: 5px 20px;
    text-align: center;
    /* margin: 10px 0px 50px -50px; */
    transition: all 0.5s;
}

.copy-component-title {
    color: var(--primary-gray-dark);
    font-size: 14px;
}

.copy-component-container:hover {
    cursor: pointer;
    background-color: #F4B956;
    box-shadow: 2px 2px 15px rgba(243,182,78,0.3);
}

@media screen and (min-width: 768px) {
    .copy-component-title {
        font-size: 24px;
    }

}
.landing-pie-overview-container {
    /* display: flex;
    flex-wrap: wrap; */
    /* margin-top: 40px; */
}

.landing-pie-container{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex:1 1;
    padding:var(--mobile-padding);
}

.landing-pie-canvas {
    /* height: 25vh; */
    margin-top: 18px;
}

.landing-pie-legend-container{
    display: flex;
    width: 74%;
    justify-content: space-evenly;
    align-items: center;
    margin: 24px auto 0px;
}

.landing-pie-legend-element {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-pie-legend-element span {
    margin: 5px 5px;
    color: white;
    font-size: 16px;
}

.landing-pie-legend-circle{
    border-radius: 50%;
    width: 8px;
    height:8px;
}

.landing-asset-overview-container {
    flex:1.5 1;
    padding: var(--mobile-padding);
    margin-top: 40px;
}

.landing-title {
    display: inline-block;
    font-size: 3vw;
    color: var(--primary-yellow);
    margin-bottom: 12px;
}

.landing-title-units {
    font-size: 1.5vw;
}

/* .landing-asset-overview-grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
} */

.landing-asset-overview-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 14px;
}

.asset-overview-info {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    /* padding-top: 12px; */
}

.asset-overview-element-title, .landing-mos-price, .landing-mos-price, .mos-stats-title {
    font-size: 2.4vw;
    color: white;
    margin-right: 10px;
}

.landing-mos-price {
    margin:unset;
}

.asset-overview-element-value, .landing-mos-price-value, .mos-stats-value, .landing-pie-legend-value {
    font-size: 3.6vw;
    color: var(--primary-yellow);
    font-weight: bold;
}

.asset-overview-element-value-usd {
    font-size: 3vw;
    color: var(--primary-yellow);
    text-align: right;
}

.landing-pie-legend-value {
    font-weight:normal;
}

@media screen and (min-width: 768px) {
    .landing-pie-overview-container {
        margin-top: unset;
    }

    .landing-pie-overview-container {
        display: flex;
        flex-wrap: wrap;
    }

    .landing-pie-container {
        margin: unset;
    }
    
    .landing-pie-canvas {
        height: 36vh;
    }

    .landing-asset-overview-container {
        margin: unset;
        margin-left: 20px;
        padding: var(--web-padding);
    }

    .landing-asset-overview-element {
        margin: 14px 0px;
        padding: 18px 22px;
    }

    .landing-title {
        font-size: 2vw;
    }

    .asset-overview-element-title, .landing-mos-price, .mos-stats-title {
        font-size: 1.3vw;
    }

    .asset-overview-element-value, .landing-mos-price-value, .mos-stats-value, .landing-pie-legend-value {
        font-size: 1.7vw;
    }

    .asset-overview-element-value-usd{
        font-size: 1.1vw;
    }

}
.landing-mos-statistics-container {
    /* padding: var(--mobile-padding); */
    margin: 40px 0px;
    display: flex;
    overflow: hidden;
    /* position: relative; */
}

.landing-mos-stats-info {
    padding: var(--mobile-padding);
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* min-height: 25vh; */
}

.landing-mos-stats-element {
    display: flex;
    align-items: center;
}

.mos-stats-day {
    padding: 12px 0px;
}

.mos-stats-gb1 {
    display: inline-block;
    margin-bottom: 6px;
}

.mos-statistics-background{
    margin-left: auto;
}

.mos-statistics-background img{
    /* max-height: 40vh;
    min-width: 200%; */
    width: 110vw;
    max-height: 40vh;
}

@media screen and (min-width: 1000px) {
    .landing-mos-stats-info {
        min-height: 32vh
    }
}
@media screen and (min-width: 768px) {
    .landing-mos-stats-info {
        padding: var(--web-padding);
    }

    .landing-mos-stats-title {
        font-size: 2vw;
    }


    .mos-statistics-background img{
        height: unset;
        /* min-width: unset; */
        max-height: unset;
        max-width: 50vw;
    }
    

}
.landing-mos-info-container {
    margin: 20px 0px;
    padding: var(--mobile-padding);
}

.landing-mos-info-row-mobile {
    display: flex;
    justify-content: space-between;
    margin: 16px 0px;
}

.landing-mos-info-row-mobile-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 50%;
}

.landing-mos-info-mobile-title {
    color: gray;
    font-size: 2.4vw;
    margin: 0px 6px;
}

.landing-mos-info-mobile-value {
    font-size: 3.6vw;
}

.landing-mos-info-mobile-title a{
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-gray);
    font-size: 2vw;
    padding-left: 4px;
}

.vertical-rule {
    background: rgba(243, 182,78, 0.5);
    width: 1px;
    margin: 8px 0px;
}

.horizontal-rule {
    width: 100%;
    background: rgba(243, 182,78, 0.5);
    height: 1px
}


/* WEB */
.landing-mos-info-web-container {
    padding: var(--web-padding);
}

.mos-info-web-row {
    display: flex;
    flex:1 1;
}

.mos-info-top-row-element, .mos-info-bottom-row-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:calc(100%/4);
}

.mos-info-bottom-row-element {
    width: calc(100%/3);
}

.mos-info-web-title{
    color: var(--primary-gray);
    font-size: 1.3vw;
    text-align: center;
    padding-bottom: 16px;
}

.mos-info-web-title a {
    cursor: pointer;
    text-decoration: none;
    font-size: 1vw;
    color: var(--primary-gray);
    padding-left: 8px;
    margin-bottom: -2px;
}

.mos-info-question-mark {
    color: var(--primary-gray);
    font-weight: 400;
}

.mos-info-web-value{
    font-size: 1.8vw;
    font-weight: 600;
}

.vertical-rule-web {
    background: rgba(243, 182,78, 0.5);
    width: 1px;
    margin: 8px 0px;
}

.horizontal-rule-web {
    width: 100%;
    background: rgba(243, 182,78, 0.5);
    height: 1px;
    margin: 16px 0px;
}
.landing-table-container {
    padding: var(--mobile-padding);
    margin-top: 40px;
}

.landing-table-web{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    color: white;
}

.landing-table-web-header {
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.landing-table-web-data {
    border-top: 1px solid rgba(137,137,137,0.6);
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 10px 8px;
}

.landing-table-mobile {
    display: grid;
    grid-template-columns: 40px repeat(3, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    color: white;
    font-size: 12px;
    margin: 20px 10px;
    border: thin solid var(--primary-gray);
    border-radius: 6px;
}

.landing-table-title{
    color: var(--primary-yellow);
    font-size: 16px;
    margin: 16px;
    margin-top: 16px;
}

.landing-table-cells-1{
    /* border-right: thin solid var(--primary-gray); */
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
}

.landing-table-mobile-cells-upper-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1 1;
    border-bottom: thin solid var(--primary-gray);
    border-left: thin solid var(--primary-gray);
    padding: 5px;
    min-height: 74px;
}

.landing-table-mobile-cells-lower-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1 1;
    padding: 5px;
    min-height: 74px;
    border-left: thin solid var(--primary-gray);
}

.ltm-vertical-divider {
    align-self: stretch;
    background-color: var(--primary-gray);
    width: 1px;
}
 
.landing-table-question-mark {
    color: var(--primary-yellow);
    font-weight: 400;
    cursor: pointer;
}


@media screen and (min-width: 768px) {
    .landing-table-container {
        padding: var(--web-padding);
    }
}

.landing-line-chart-container {
    margin: 20px 0px;
    padding: var(--mobile-padding);
}

.landing-line-chart-canvas {
    height: 60vh;
    margin: 12px 0px;
}

.landing__line-chart-custom-tooltip {
    padding: 10px 16px;
    background: var(--primary-bg);
    color: var(--white80);
    border-radius: 10px;
}

@media screen and (min-width: 768px) {
    .landing-line-chart-container {
        margin: 40px 0px;
    }
}

.tdvv__container {
    color: white;
    padding: 8vw;
}

.tdvv__title {
    color: #F3B64E;
    font-size: 4vw;
}

.tdvv__desc {
    font-size: 3vw;
    color: var(--white70);
    line-height: 1.2;
}

.tdvv__right-contaner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 32px;
}

.tdvv__circle-background {
    background-image: url(/static/media/circle_golden.d07ce34f.svg);
    height: 56vw;
    width: 56vw;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tdvv__blue-circle-background {
    background-image: url(/static/media/circle_blue.1da34f3e.svg);
}

.tdvv__circle-title {
    font-size: 2.5vw;
    fill: var(--white30);
}

.tdvv__circle-value {
    font-size: 4vw;
    font-weight: 500;
    line-height: 1.1;
    fill: white;
}

.tdvv__item-container {
    padding: 16px 0px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F3B64E;
}

.tdvv__item-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ttdv__item-dot {
    height: 10px;
    width: 10px;
    background-color: #176EBF;
    border-radius: 50px;
    margin-right: 3vw;
}

.ttdv__item-title {
    font-size: 3.5vw;
    color: var(--white50);
}

.tdvv__item-amount {
    font-size: 4vw;
    font-weight: bold;
    color: white;
}

.tdvv__item-price {
    font-size: 3vw;
    font-weight: 700;
    color: var(--white90);
}


@media screen and (min-width: 768px) {
    .tdvv__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4vw 6vw;
    }

    .tdvv__left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 20vw;
    }

    .tdvv__title {
        font-size: 2vw;
        margin-bottom: 0.8vw;
    }

    .tdvv__desc {
        font-size: 1vw;
        color: white;
    }

    .tdvv__right-contaner {
        margin-bottom: 0;
        width: 26vw;
    }

    .tdvv__circle-background {
        height: 20vw;
        width: 20vw;
    }

    .tdvv__circle-title {
        font-size: 0.8vw;
        margin-top: -1.8vw;
    }

    .tdvv__circle-value {
        font-size: 1vw;
        margin-top: 4px;
        font-weight: 500;
    }

    .ttdv__item {
        display: flex;
        flex-direction: row;
    }

    .tdvv__item-container {
        border-bottom-width: 0px;
        margin-right: 5vw;
    }
    
    .tdvv__item-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.4vw;
    }
    
    .ttdv__item-dot {
        height: 10px;
        width: 10px;
        background-color: #176EBF;
        border-radius: 50px;
        margin-right: 0.7vw;
    }
    
    .ttdv__item-title {
        font-size: 1.2vw;
        /* margin-bottom: 0.4vw; */
        color: var(--white50);
    }
    
    .tdvv__item-amount {
        font-size: 1.4vw;
        font-weight: 500;
        color: white;
    }
    
    .tdvv__item-price {
        font-size: 1vw;
        font-weight: 400;
        color: var(--white90);
    }
}
.ans__container {
    padding: 8vw;
    margin-top: 6vw;
}

.ans__p-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3vw 3vw;
    margin-top: 4vw;
}

.ans__p-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ans__p-title {
    color: #F3B64E;
    font-size: 4vw;
}

.ans__p-icon {
    width: 3vw;
    height: 3vw;
    margin-left: 1vw;
    border-radius: 50px;
}

.ans__p-date {
    font-size: 3vw;
    color: var(--white60);
    margin-left: auto;
}

.ans__horizontal-divier {
    height: 1px;
    background-color: #F3B64E;
    margin: 5vw 0vw;
}

.ans__vertical-divier {
    width: 1px;
    background-color: #F3B64E;
}

.ans__item {
    display: flex;
    flex-direction: row;

    padding-bottom: 5vw;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #F3B64E;
}

.ans__item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ans__item-title {
    font-size: 4vw;
    font-weight: bold;
    color: white;
    text-align: center;
    word-break: break-word;
    margin: 0 20px;
}

.ans__item-value {
    font-size: 3vw;
    color: var(--white40);
    text-align: center;
    line-height: 1.1;
}

.ans__item-subvalue {
    font-size: 2vw;
    color: var(--white90);
    text-align: center;
    margin-top: 0.5vw;
}

.ans__item-container a {
    text-decoration: none;
    color: var(--white90);
}

.ans__item-tooltip {
    text-align: center;
}

.ans__item-question-mark {
    color: var(--primary-gray);
    font-size: 1.8vw;
}

.ans__slider {
    margin: 8vw 6vw 0px 6vw;
    padding-bottom: 8vw;
}

.MuiSlider-markLabel {
    color: white !important;
    font-size: 3vw !important;
}

.MuiSlider-markLabelActive {
    color: #F3B64E !important;
}

.tooltip-img {
    height: 25vh;
    padding: 8px 0px;
}

@media screen and (min-width: 768px) {
    .ans__container {
        padding: 4vw 6vw;
    }

    .ans__header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .ans__title-container {
        margin-right: auto;
    }

    .ans__p-container {
        padding: 0.5vw 1vw;
        flex-direction: column;
        align-items: flex-start;
        width: 19vw;
        margin-top: 0px;
        margin-left: 2vw;
    }
    
    .ans__p-title {
        color: #F3B64E;
        font-size: 1.5vw;
    }
    
    .ans__p-icon {
        width: 1vw;
        height: 1vw;
        margin-left: 0.5vw;
        border-radius: 50px;
    }
    
    .ans__p-date {
        font-size: 1vw;
        color: var(--white80);
        margin-left: 0;
    }

    .ans__body-container {
        display: flex;
        flex-direction: row;
        margin-top: 2vw;
        padding: 2vw 0px;

        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #F3B64E;

        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #F3B64E;
    }

    .ans__body-container-column {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ans__body-right-container {
        /* display: flex;
        flex-direction: row; */
        width: 65%;

        border-width: 1px;
        border-style: solid;
        border-color: #F3B64E;

        border-top-width: 0px;
        border-bottom-width: 0px;
        
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 1px;
        grid-row-gap: 0px;

        background-color: #F3B64E;
        margin-left: 3vw;
    }

    .ans__item-container {
        width: unset;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin: 0px 2vw; */
        padding: 1vw;

        background:
            linear-gradient(
            to left, 
            #ffffff00 15%,
            #F3B64E 15%,
            #F3B64E 85%,
            #ffffff00 85%
            )
            left 
            bottom
                no-repeat
            ,
            var(--primary-gray-dark)
            top
            left
        ; 
        background-size:100% 1px , 100% auto;
    }

    .ans__item-container:nth-last-child(-n+3) {
        background:
            var(--primary-gray-dark)
            top
            left
        ; 
        background-size:100%;

    }
        
    .ans__item-title {
        font-size: 1.3vw;
        font-weight: 500;
        margin: 0px;
    }
    
    .ans__item-value {
        font-size: 1.2vw;
    }
    
    .ans__item-subvalue {
        font-size: 1vw;
        color: var(--white90);
        text-align: center;
        margin-top: 0.1vw;
    }

    .ans__item-question-mark {
        font-size: 0.8vw;
    }

    .ans__slider {
        margin: 4vw 3vw;
        padding-bottom: unset;
    }

    .MuiSlider-markLabel {
        font-size: 1.2vw !important;
    }
}

#float-label {
  display: flex;
  flex-direction: column;
  /* min-width: 350px; */
  position: relative;
  margin: 8px 0px;
}

#float-label input {
  width: 100%;
  height: 56px;
  padding: 0px 16px 0 10px;
  outline: 0;
  color: white;
  background-color: transparent;
  font-size: 16px;
  border: none;
}

#float-label label {
  font-size: 16px;
  padding: 0 12px;
  margin-top: -12px;
  color: #898989;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 26px) scale(1);
          transform: translate(0, 26px) scale(1);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  -webkit-transform: translate(0, 4px) scale(0.75);
          transform: translate(0, 4px) scale(0.75);
  color: white;
  background-color: #282828;
  margin-left: 8px;
  padding: 0 6px;
}

#float-label .Active {
  -webkit-transform: translate(0, 4px) scale(0.75);
          transform: translate(0, 4px) scale(0.75);
  color: white;
  background-color: #282828;
  margin-left: 8px;
  padding: 0 6px;
}

#float-label .input-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #898989;
  border-radius: 4px;
  align-items: center;
  padding-right: 12px;
}

#float-label .action-label {
  text-align: right;
  font-size: 14px;
  color: white;
}
#float-label .action-label:hover {
  cursor: pointer;
  text-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

#float-label .action-icon {
  border-radius: 50px;
}

#float-label .action-icon:hover {
  box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

#float-label .note {
  color: white;
  font-size: 13px;
  padding: 0 12px;
  margin-top: 4px;
}

@media screen and (min-width: 768px) {
  #float-label input {
    font-size: 16px;
  }

  #float-label label {
    margin-top: -10px;
    font-size: 16px;
  }

  #float-label .action-label {
    font-size: 16px;
    color: white;
  }

  #float-label .note {
    font-size: 12px;
  }
}
.asv__container {
    padding: 8vw;
    margin-top: 6vw;
}

.asv-table-web {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    color: white;

    padding: 2vw 2vw 3vw 2vw;
    margin-top: 3vw;
}

.asv-table-web-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 4vw;
    text-align: center;
    color: #F3B64E;

    padding-top: 1vw;
}

.asv-table-web-data {
    font-size: 4vw;
    text-align: center;
    padding-top: 1vw;
}

.asv-table-web-divide-top {
    border-top: 1px solid rgba(137,137,137,0.6);
    margin-top: 2vw;
    padding-top: 2vw;
}

.asv__container a, .asv-table-web a{
    text-decoration: none;
}

.asv-table-question-mark {
    color: var(--primary-yellow);
    font-weight: 400;
}

.asv-table-question-mark-mobile {
    color: var(--primary-gray);
    font-weight: 400;
}

.asv-table-end-stake-note {
    color: white;
    font-size: 12px;
    text-align: center;
}

.eesm__container {
    padding: 2vw;
    color: white;
}

.asv__early-end-modal-link {
    color: var(--primary-yellow);
    text-decoration: none;
    cursor: pointer;
    padding-left: 6px;
}

@media screen and (min-width: 768px) {
    .asv__container {
        padding: 4vw 6vw;
    }
    /* TABLE WEB */
    .asv-table-web {
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px; 
        color: white;
        padding: 0px;
        margin-top: 0px;

        /* border-top: 1px solid rgba(137,137,137,0.6); */
        /* border-left: 1px solid rgba(137,137,137,0.6); */
        /* border-right: 1px solid rgba(137,137,137,0.6); */
    }

    .asv-table-web-header {
        border-left: 1px solid rgba(137,137,137,0.6);
        /* border-bottom: 1px solid rgba(137,137,137,0.6); */
        padding: 8px 0px;
        /* padding-top: 20px; */
        font-weight: bold;
        font-size: 0.9vw;
        text-align: center;
        color: white;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .asv-table-web-header:nth-child(1), .asv-table-web-new-header:nth-child(1){
        border-left: unset;
    }

    .asv-table-web-data {
        border-top: 1px solid rgba(137,137,137,0.6);
        border-left: 1px solid rgba(137,137,137,0.6);
        padding: 10px 8px;
        font-size: 1vw;
        text-align: right;
    }


    .asv-table-web-new {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        /* grid-template-columns: repeat(14, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        color: white;
    }

    .asv-table-web-new-history {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        /* grid-template-columns: repeat(13, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        color: white;

    }

    .asv-table-web-new-header {
        border-left: 1px solid rgba(137,137,137,0.6);
        /* font-weight: bold; */
        font-size: 0.9vw;
        text-align: center;
        padding: 10px 8px;

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .asv-table-web-new-header a {
        text-decoration: none;
        cursor: pointer;
        color: var(--primary-yellow);
    }

    .asv-table-web-new-header span {
        white-space: nowrap;
    }

    /* .asv-table-web-new-header:nth-child(4),
    .asv-table-web-new-header:nth-child(11),
    .asv-table-web-new-header:nth-child(14) {
        border-bottom: 1px solid rgba(137,137,137,0.6);
    } */

    .asv-table-web-data:nth-child(1){
        border-left: unset;
    }

    .asv-exit-btn {
        box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
        color: #F3B64E;
        padding: 2px;
        text-align: center;
    }

    .asv-exit-btn:hover {
        cursor: pointer;
        -webkit-transform: scale(1.05,1.05);
                transform: scale(1.05,1.05);
    }
    /* end */
}

.cl__container {
    padding: 8vw;
    margin-top: 6vw;
    display: flex;
    flex-direction: column;
}

.cl__swipe-icon {
    height: 5.5vw;
    width: 5.5vw;
    margin: 3vw;
}

.cl__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cl__tooltip-question-mark {
    color: var(--primary-gray);
    padding: 4px 8px 4px 10px;
    font-size: 12px;
}

.cl__title-qm {
    margin-top: -3px;
    padding-left: 8px;
}

.cl__tooltip-question-mark a {
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-gray);
}

@media screen and (min-width: 768px) {
    .cl__container {
        padding: 4vw 6vw;
    }
    
    .cl__swipe-icon {
        height: 2.5vw;
        width: 2.5vw;
        margin: -2vw 3vw 0px 3vw;
    }

    .cl__rotate-icon {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .cl__top-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 1.5vw;
    }

    .cl__right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 11vw;
    }

    .cl__bottom-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
    }

    .cl__tooltip-question-mark {
        padding: unset;
        padding-right: 8px;
        font-size: unset;
    }

    .cl__title-qm {
        padding-top: 18px;
        margin-top: unset;
    }
}
