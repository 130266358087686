.app-container {
    transition: 0.5s;
    min-height: 100vh;
}

.content-container {
    padding: 3.6vw 4vw 5.6vw;
}


@media only screen and (min-width: 768px) {
    .content-container {
        padding: 4vw 8vw 5vw;
    }
}