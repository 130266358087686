#float-label {
  display: flex;
  flex-direction: column;
  /* min-width: 350px; */
  position: relative;
  margin: 8px 0px;
}

#float-label input {
  width: 100%;
  height: 56px;
  padding: 0px 16px 0 10px;
  outline: 0;
  color: white;
  background-color: transparent;
  font-size: 16px;
  border: none;
}

#float-label label {
  font-size: 16px;
  padding: 0 12px;
  margin-top: -12px;
  color: #898989;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(0, 4px) scale(0.75);
  color: white;
  background-color: #282828;
  margin-left: 8px;
  padding: 0 6px;
}

#float-label .Active {
  transform: translate(0, 4px) scale(0.75);
  color: white;
  background-color: #282828;
  margin-left: 8px;
  padding: 0 6px;
}

#float-label .input-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #898989;
  border-radius: 4px;
  align-items: center;
  padding-right: 12px;
}

#float-label .action-label {
  text-align: right;
  font-size: 14px;
  color: white;
}
#float-label .action-label:hover {
  cursor: pointer;
  text-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

#float-label .action-icon {
  border-radius: 50px;
}

#float-label .action-icon:hover {
  box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

#float-label .note {
  color: white;
  font-size: 13px;
  padding: 0 12px;
  margin-top: 4px;
}

@media screen and (min-width: 768px) {
  #float-label input {
    font-size: 16px;
  }

  #float-label label {
    margin-top: -10px;
    font-size: 16px;
  }

  #float-label .action-label {
    font-size: 16px;
    color: white;
  }

  #float-label .note {
    font-size: 12px;
  }
}