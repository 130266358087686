.auction-top-container {
    color: white;
    display: flex;
    flex-direction: column;
    padding: var(--mobile-padding);
}

.auction-top-logo-container{
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.auction-top-title {
    font-size: 18px;
    color: var(--primary-yellow);
}

.auction-top-subtitle {
    font-size: 14px;
    font-weight: 300;
}

.auction-rate-container{
    display: flex;
    flex-direction: column;
}

.auction-rate-title {
    font-size: 16px;
    color: var(--primary-yellow);
}

.auction-logo{
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.auction-bid-container {
    display: flex;
    flex-direction: column;
    padding: var(--mobile-padding);
    flex:1;
}

.auction-usdt-balance {
    color: var(--white);
    text-align: right;
    /* margin-bottom: -14px; */
    font-size: 12px;
}

.bid-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px; 
}

.bid-value-container {
    margin-top: 10px;
    padding: 20px;
}

.bid-value-mos-container {
    padding: 20px;
    margin-bottom: 10px;
}

.bid-coin-logo {
    width: 30px;
}

.bid-value-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
    flex:1;
    padding-left: 16px;
    max-width: 85%;
}

.auction-deposit-input {
    font-weight: bold;
    color: var(--primary-yellow);
    font-size: 18px;
    text-align: end;
    /* width:80%; */
    border: none;
    flex:1;
    max-width: 75%;
}

.auction-deposit-input:hover {
    border-radius: 5px;
    background-color: var(--white10);
}

.auction-rate-value {
    font-weight: bold;
    color: var(--primary-yellow);
    font-size: 18px;
    text-align: right;
}

.auction-input-max {
    padding-left: 8px;
    font-size: 14px;
    color: white;
    text-align: right;
}

.auction-input-max:hover {
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.auction-rate-sub {
    color: white;
    font-size: 14px;
    text-align: end;
}

.bid-arrow-circle {
    z-index: 2;
    margin: auto;
    padding: 8px;
    border-radius: 50%;
    transition: all 0.6s;
}

.bid-arrow {
    color: var(--primary-yellow);
    width: 20px;
    height: 20px;
}

.bid-side-picture {
    background-image: url('../../assets/images/auction/auction-pic.png');
    background-size: cover;
    margin-left: 24px;
    flex: 1;
}

.auction-bottom-container {
    padding: var(--mobile-padding);
    margin-top: 40px;
}

.bid-footnote {
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 18px;
}

.bid-referral-wrapper {
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    padding: 0px 0px;
}

.bid-referral-wrapper input{
    font-weight: bold;
    color: var(--primary-yellow);
    font-size: 16px;
    text-align: end;
    /* width:80%; */
    border: none;
    flex:1;
    max-width: 75%;
}

.bid-referral-warning {
    color: white;
    text-align: center;
    font-size: 12px;
    padding-bottom: 8px;
}


/* TABLE MOBILE */
.auction-table-mobile {
    display: grid;
    grid-template-columns: 40px repeat(3, 1fr);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    color: white;
    font-size: 12px;
    margin: 20px 10px;
    border: thin solid var(--primary-gray);
    border-radius: 6px;
}

.auction-table-title{
    color: var(--primary-yellow);
    font-size: 16px;
    margin: 16px;
    margin-top: 16px;
}

.auction-table-cells-1{
    /* border-right: thin solid var(--primary-gray); */
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
}

.auction-table-mobile-cells-upper-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1;
    border-bottom: thin solid var(--primary-gray);
    border-left: thin solid var(--primary-gray);
    padding: 5px;
    /* height: 96px; */
}

.auction-table-mobile-cells-lower-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:1;
    padding: 5px;
    height: 96px;
    border-left: thin solid var(--primary-gray);
}

.auction-table-mobile-exit-btn {
    margin: 0px auto 34px;
    width: 50%;
    color: var(--primary-yellow);
    text-align: center;
}

.auction-table-mobile-exit-btn:hover {
    transform: scale(1.05, 1.05);
}

.auction-table-mobile-value {
    word-break: break-all;
}
/* end */


/* TABLE WEB */
.auction-table-web{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    color: white;
}

.auction-table-web-header {
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
}

.auction-table-web-data {
    border-top: 1px solid rgba(137,137,137,0.6);
    border-left: 1px solid rgba(137,137,137,0.6);
    padding: 10px 8px;
}
/* end */

.auction-exit-btn {
    box-shadow: 2px 2px 15px rgba(0,0,0,0.7);
}

.auction-exit-btn:hover {
    cursor: pointer;
    transform: scale(1.05,1.05);
}

@media screen and (min-width: 768px) {
    .auction-top-container {
        flex-direction: row;
        /* align-items: center; */
        padding: var(--web-padding);
    }

    .auction-top-logo-container{
        margin: unset;
        width: 50%;
        max-width: 40vw;
        margin-left: auto;
    }

    .auction-top-title {
        font-size: 2vw;
    }

    .auction-top-subtitle {
        font-size: 18px;
    }


    .auction-logo{
        width:60px;
        height: 60px;
    }

    .auction-bid-container {
        padding: var(--web-padding);
    }


    .bid-value-container, .bid-value-mos-container {
        padding: 32px 60px;
    }

    .auction-rate-value, .auction-deposit-input {
        font-size: 28px;
    }

    .auction-input-max {
        font-size: 20px;
    }

    .auction-rate-sub {
        font-size:20px;
    }

    .bid-coin-logo {
        width: 48px;
    }

    .bid-arrow {
        width: 32px;
        height: 32px;
    }

    .bid-footnote, .auction-usdt-balance {
        font-size: 16px;
    }

    .auction-bottom-container {
        padding: var(--web-padding);
    }

    .bid-value-wrapper {
        max-width: unset;
    }

    .auction-deposit-input{
        max-width: unset;
    }

    .bid-referral-wrapper {
        font-size: 18px;
    }

    .bid-referral-warning {
        text-align: right;
        width: 75%;
        align-self: end;
        font-size: 14px;
    }
}