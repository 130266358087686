.landing-mos-statistics-container {
    /* padding: var(--mobile-padding); */
    margin: 40px 0px;
    display: flex;
    overflow: hidden;
    /* position: relative; */
}

.landing-mos-stats-info {
    padding: var(--mobile-padding);
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* min-height: 25vh; */
}

.landing-mos-stats-element {
    display: flex;
    align-items: center;
}

.mos-stats-day {
    padding: 12px 0px;
}

.mos-stats-gb1 {
    display: inline-block;
    margin-bottom: 6px;
}

.mos-statistics-background{
    margin-left: auto;
}

.mos-statistics-background img{
    /* max-height: 40vh;
    min-width: 200%; */
    width: 110vw;
    max-height: 40vh;
}

@media screen and (min-width: 1000px) {
    .landing-mos-stats-info {
        min-height: 32vh
    }
}
@media screen and (min-width: 768px) {
    .landing-mos-stats-info {
        padding: var(--web-padding);
    }

    .landing-mos-stats-title {
        font-size: 2vw;
    }


    .mos-statistics-background img{
        height: unset;
        /* min-width: unset; */
        max-height: unset;
        max-width: 50vw;
    }
    

}